var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightbox"},[_c('div',{staticClass:"title titleborder"},[_vm._v(" "+_vm._s(_vm.form.lectureTitle)+" "),_c('div',{staticClass:"rightreleaseTime"},[_vm._v("讲座时间："+_vm._s(_vm.form.lectureTime))])]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.form.lectureContent)}}),_c('div',[(_vm.form.signInState != -1)?_c('div',[_c('div',[(_vm.form.signInState == 2)?_c('div',[(_vm.form.signInState == 2)?_c('div',{staticClass:"button"},[_vm._v("已签到")]):_vm._e()]):_c('div',[(_vm.form.signInState == 1)?_c('div',{staticClass:"button"},[_vm._v("已报名")]):_vm._e()])])]):_c('div',[_c('div',[(
            new Date().getTime() <
            new Date(
              new Date(_vm.form.lectureTime).getFullYear() +
                '/' +
                (new Date(_vm.form.lectureTime).getMonth() + 1) +
                '/' +
                new Date(_vm.form.lectureTime).getDate() +
                ' ' +
                '23:00:00'
            ).getTime() +
              1000 * 60 * 60 * 24
          )?_c('div',[_c('div',{staticClass:"button button1",on:{"click":function($event){return _vm.signUp(_vm.form.id)}}},[_vm._v("去报名")])]):_c('div',[_c('div',{staticClass:"button"},[_vm._v("已结束")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }